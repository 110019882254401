import React from "react";
import PropTypes from "prop-types";
import renderFields from "../../renderFields";
import { REDUX_FORM_NAME, BooleanInput } from 'react-admin'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { get } from 'lodash'
import { change, getFormValues } from 'redux-form'
import { connect } from 'react-redux'

const Widget = ({ dispatch, ...props }) => {
  var checked = get(props.record, props.fieldName) != null
  const handleOnChange = (event, value) => {
    props.context.dispatch(change(REDUX_FORM_NAME, props.fieldName, value === true ? {} : null))
  }

  return (
      <div className="objectType">
          <div><strong>{props.required && props.schema.title}</strong></div>
      {!props.required && <FormGroup><FormControlLabel
        htmlFor={props.id}
        control={
          <Switch
            id={props.id}
            color="primary"
            checked={checked}
            onChange={handleOnChange}
          />
        }
        label={
          <span>{props.label}</span>
        }
      /></FormGroup>}
      {(checked || props.required) && renderFields(
        props.schema,
        props.theme,
        props.fieldName && props.fieldName + ".",
        props.context
      )}
    </div>
  );
};

Widget.propTypes = {
  schema: PropTypes.object.isRequired,
  fieldName: PropTypes.string,
  label: PropTypes.string,
  theme: PropTypes.object,
  context: PropTypes.object
};

const mapStateToProps = (state, props) => {
  return {
      record: getFormValues(REDUX_FORM_NAME)(state)
  }
};

export default connect(mapStateToProps)(Widget);
