import { stringify } from 'query-string';
import {
    fetchUtils,
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY,
} from 'ra-core';
import { isArray } from 'lodash'

const isEntreEndpoint = resource => ['entreintegrationlogs', 'entreintegrationsettings', 'entreidmappings'].indexOf(resource) !== -1
let isPostWithParameter = ['settingsvalidation/validateintegration'];
export const VALIDATE = 'VALIDATE';
/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 * @example
 * GET_LIST     => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = fetchUtils.fetchJson) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        let instanceId = null
        if (isEntreEndpoint(resource)) {
            instanceId = params.id
            delete params.target
        }

        let url = '';
        const options = {};
        switch (type) {
            case GET_LIST: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;

                const query = {
                    sort: JSON.stringify([{property: field, direction: order}]),
                    filter: JSON.stringify(Object.keys(params.filter).map(k => {

                        let searchOperation = 'eq';
                        if (isArray(params.filter[k])) {
                            searchOperation = 'in';
                        } else if (params.filter[k] && typeof params.filter[k] === 'object' && params.filter[k].operation) {
                            searchOperation = params.filter[k].operation;
                        }

                        let searchText = params.filter[k];
                        if (isArray(params.filter[k])) {
                            searchText = params.filter[k].join(',');
                        } else if (params.filter[k] && typeof params.filter[k] === 'object') {
                            searchText = params.filter[k].searchText;
                        }

                        return {
                            property: k,
                            operation: searchOperation,
                            value: searchText
                        }
                    })),
                    start: (page - 1) * perPage + 1,
                    limit: perPage
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case GET_ONE:
                url = `${apiUrl}/${resource}/${params.id}`;
                break;
            case GET_MANY_REFERENCE: {
                const { page, perPage } = params.pagination;
                const { field, order } = params.sort;
                const query = {
                    filter: JSON.stringify(!params.target ? [] : [{property: params.target, value: params.id}]),
                    sort: JSON.stringify([{property: field, direction: order}]),
                    start: (page - 1) * perPage + 1,
                    limit: perPage,
                    instanceId: instanceId
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            case UPDATE:
                url = `${apiUrl}/${resource}/${(params.trailingPath || '').replace(/^\//, '')}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case VALIDATE:
            case CREATE:
                url = `${apiUrl}/${resource}`;
                if (isPostWithParameter.includes(resource)) {
                    url = `${apiUrl}/${resource}/${params.id}`;
                }
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${apiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            case GET_MANY: {
                const query = {
                    start: 0,
                    limit: 100,
                    filter: JSON.stringify([{property: "id", operation: "In", value: params.ids.join(",")}])
                };
                url = `${apiUrl}/${resource}?${stringify(query)}`;
                break;
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return { url, options };
    };

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        const { headers, json } = response;
        switch (type) {
            case GET_LIST:
            case GET_MANY_REFERENCE:
            case GET_MANY:
                let i = 1;
                return {
                    data: json.results.map(res => {
                        if (isEntreEndpoint(resource)) {
                            res.id = i++;
                        }

                        return res;
                    }),
                    total: json.totalRows,
                };
            case CREATE:
                if (isPostWithParameter.includes(resource)) {
                    return { data: { ...params.data, id: json.id, message: json.message } };
                }
                return { data: { ...params.data, id: json.id } };
            case DELETE_MANY:
                return { data: json || [] };
            default:
                return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'PUT',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${apiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            }));
        }
        const { url, options } = convertDataRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options).then(response =>
            convertHTTPResponse(response, type, resource, params)
        );
    };
};
