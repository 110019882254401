import React from 'react';
import { Button } from '@material-ui/core'
import { CardActions, CreateButton, ExportButton } from 'react-admin';

export const Actions = ({
    bulkActions,
    basePath,
    currentSort,
    hasCreate,
    exporter,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    total,
    setFilters
}) => (
    <CardActions>
        <Button variant="text" onClick={() => setFilters({})}>Clear Filters</Button>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        { hasCreate && <CreateButton basePath={basePath} />}
        { exporter && <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />}
    </CardActions>
)