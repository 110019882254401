import React from 'react';
import {
    required, TabbedForm, FormTab, SelectInput, ReferenceInput, TextInput, BooleanInput
} from 'react-admin';

class ErrorFilterForm extends React.Component {
    render() {
        const { isEditing = false, permissions, LandingForm, ...props } = this.props

        const checkRegex = (input) => {
            if (input === "") { return false }
            if (!isValid(input)) { return "Invalid input"; }
            try {
                let x = new RegExp(input)
                return false;
            }
            catch (e) {
                return "Invalid input";
            }
        }

        const isValid = (input) => {
            if (input === null) {
                return true;
            }
            let text = String(input);
            console.error("Inside isValid");
            for (let i = 0; i < text.length; i++) {
                if (text.length > 1 && (text[i] === "/" || text[i] === "\"")) {
                    if (text[i - 1] !== "\\") {
                        return false;
                    }
                }
            }
            return true;
        }

        return (
            permissions == null ? null :
                permissions.group.groupValue <= 100
                    ?
                    <LandingForm {...props} permissions={permissions} />
                    :
                    <TabbedForm {...props} defaultValue={{ entreDataSource: 1 }} className="width-100em-sub-div">
                        <FormTab label="general">
                            {!isEditing && <ReferenceInput source="integrationId" perPage={100} reference="integrations" validate={required()}><SelectInput optionText="name" /></ReferenceInput>}
                            {!isEditing && <TextInput source="FilterExceptionRegex" label="Message regex filter" validate={[required(), checkRegex]} />}
                            {!isEditing && <TextInput source="ResultMessage" validate={required()} />}
                            {!isEditing && <TextInput source="ExceptionRegexGroupString" label="Message regex filter with parameters" validate={checkRegex} />}
                            {!isEditing && <BooleanInput source="isErrorAllowed" validate={required()} defaultValue={false} />}

                            {isEditing && <ReferenceInput source="integrationId" perPage={100} reference="integrations" validate={required()}><SelectInput optionText="name" /></ReferenceInput>}
                            {isEditing && <TextInput source="filterExceptionRegex" reference="filterExceptionRegex" label="Message regex filter" validate={[required(), checkRegex]} />}
                            {isEditing && <TextInput source="resultMessage" reference="resultMessage" validate={required()} />}
                            {isEditing && <TextInput source="exceptionRegexGroupString" label="Message regex filter with parameters" validate={checkRegex} />}
                            {isEditing && <BooleanInput source="isErrorAllowed" validate={required()} defaultValue={false} />}
                        </FormTab>
                    </TabbedForm>
        )
    }
}

export default ErrorFilterForm;