/* eslint-disable import/no-anonymous-default-export */
import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
} from 'ra-core';
import FakeRest from 'fakerest';

let portalAdminResources = ['companies', 'endpoints', 'companies/subdivisions'];
let fakerests = {};
let companyId;

const convertHTTPResponse = (response, type, resource) => {
    const { json } = response;
    switch (type) {
        case GET_LIST:
        case GET_MANY_REFERENCE:
        case GET_MANY:
            var raw = json.results == undefined ? json.data : json.results;

            return raw.map(res => {
                if (resource == 'companies/subdivisions') {
                    res.id = res.subdivisionId
                }

                if (resource == 'companies') {
                    res.id = res.companyId
                }

                if (resource == 'endpoints') {
                    res.id = res.endpointId
                }

                return res;
            });
    }
};

function getResponse(type, resource, params) {
    let restServer = fakerests[resource];
    switch (type) {
        case GET_LIST: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: [field, order],
                range: [(page - 1) * perPage, page * perPage - 1],
                filter: resource != "companies" ? params.filter : (company) => {
                    if (!params.filter.q) { return true; }
                    if (company.companyName.toLowerCase().indexOf(params.filter.q.toLowerCase()) != -1) { return true; }
                    if (company.countryCode.toLowerCase().indexOf(params.filter.q.toLowerCase()) != -1) { return true; }
                    let customerNumberString = company.customerNumber.toString();
                    if (customerNumberString.toLowerCase().indexOf(params.filter.q.toLowerCase()) != -1) { return true; }
                    return false
                },
            };
            return {
                data: restServer.getAll(resource, query),
                total: restServer.getCount(resource, {
                    filter: params.filter,
                }),
            };
        }
        case GET_ONE:
            return {
                data: restServer.getOne(resource, params.id, { ...params }),
            };
        case GET_MANY:
            return {
                data: restServer.getAll(resource, {
                    filter: { id: params.ids },
                }),
            };
        case GET_MANY_REFERENCE: {
            const { page, perPage } = params.pagination;
            const { field, order } = params.sort;
            const query = {
                sort: [field, order],
                range: [(page - 1) * perPage, page * perPage - 1],
                filter: { ...params.filter, [params.target]: params.id },
            };
            return {
                data: restServer.getAll(resource, query),
                total: restServer.getCount(resource, {
                    filter: query.filter,
                }),
            };
        }
    }
};

export default (apiUrl, httpClient, requestHandler) => async (type, resource, params) => {
    if (portalAdminResources.includes(resource)) {
        var queryResource = resource;

        if (params.filter && params.filter.companyId) {
            companyId = params.filter.companyId;
            delete params.filter.companyId;
        }

        if (queryResource === 'companies/subdivisions' && companyId) {
            queryResource = `companies/${companyId}/querysubdivisions?pagesize=10000`;
        }
        if (queryResource === 'endpoints') { //workaround

            //console.log('fakerests, endpoints: ', fakerests)
            if (!fakerests[queryResource]) {
                fakerests[queryResource] = new FakeRest.Server();
                fakerests[queryResource].init({ [queryResource]: [] });
            }

            const endpoints = await getResponse(type, queryResource, params);
            //console.log('endpoints already present: ', endpoints);
            //if (endpoints && params.ids && endpoints.data.length > 0) {
                if (endpoints && params.ids && endpoints.data.length === params.ids?.length) {
                    //console.log('endpoints array exist');
                    return new Promise(resolve => resolve(endpoints));
                }
                else if (fakerests[queryResource].collections.endpoints.items.length > 0) {
                    const isByCompany = false;
                    const ids = [];
                    const res = await fetchAndProcessIds(apiUrl, queryResource, type, isByCompany, params, ids, httpClient)
                    fakerests[queryResource].collections.endpoints.items.sort((a, b) => a.id - b.id);

                    return new Promise(resolve => resolve(getResponse(type, queryResource, params)));
                }
            //}
            else {
                //console.log('companies: ', fakerests['companies']);
                const ids = fakerests['companies']?.collections?.companies.items.map(i => i.id);

                if (ids && ids.length > 0) {
                    //console.log('company ids: ', ids);
                    const isByCompany = true;
                    const chunkSize = 100;

                    for (let i = 0; i < ids.length; i += chunkSize) {
                        const chunk = ids.slice(i, i + chunkSize);
                        //console.log('chunk: ', chunk);
                        const res = await fetchAndProcessIds(apiUrl, queryResource, type, isByCompany, params, chunk, httpClient);
                    }
                    fakerests[queryResource].collections.endpoints.items.sort((a, b) => a.id - b.id);
                    //console.log('res: ', res);
                    //console.log("all fakerests", fakerests);
                    return new Promise(resolve => resolve(getResponse(type, queryResource, params)));
                }
                else {
                    return new Promise(resolve => resolve(getResponse(type, queryResource, params)));
                }
            }

        }
        else if (!fakerests[queryResource]) {

            var url = `${apiUrl}/${queryResource}`;
            var options = { method: 'GET' };

            return httpClient(url, options).then(response =>
                convertHTTPResponse(response, type, resource, params)
            )
                .then(result => {
                    fakerests[queryResource] = new FakeRest.Server();
                    fakerests[queryResource].init({ [queryResource]: result });
                    return getResponse(type, queryResource, params);
                });
        } else {
            return new Promise(resolve => resolve(getResponse(type, queryResource, params)));
        }
    } else {
        return requestHandler(type, resource, params);
    }
}
async function fetchAndProcessIds(apiUrl, queryResource, type, isByCompany, params, ids, httpClient) { //httpClient unnecessary
    
    if (isByCompany && isByCompany === true) {
        //console.log('ids inside: ', ids);
        const promises = ids?.map(async _id => {

            const url = `${apiUrl}/${queryResource}/?companyId=${_id}`;
            const options = { method: 'GET' };
            const response = await httpClient(url, options);
            //console.log("response: ", response.json);
            const result = await convertHTTPResponse(response, type, queryResource, params)
            //console.log('result ', result);
            result?.map(res => {
                //if (!fakerests[queryResource]?.collections?.endpoints?.items?.find(endpoint => endpoint.id === res.id)) {
                try {
                    return fakerests[queryResource].collections.endpoints.addOne(res);
                }
                catch {
                    //workaround
                    return null;
                }
               

            });
        });

        if (promises) {
           return Promise.all(promises);

        }
        else {
            return Promise.resolve();
        }
    }
    else {
        //console.log('params: ', params);
        const promises = params?.ids?.map(async _id => {

            const url = `${apiUrl}/${queryResource}/${_id}`;
            const options = { method: 'GET' };
            const response = await httpClient(url, options);
            //console.log("response: ", response.json);
            //debugger;

            const { id, ...otherprops } = response.json;
            const result = { id: response.json.endpointId, ...otherprops };
            //console.log('result ', result);
            if (!fakerests[queryResource].collections.endpoints.items.find(endpoint => endpoint.id === result.id)) {
                fakerests[queryResource].collections.endpoints.addOne(result);
            }
            else {
                //console.log('did not add: ', endpoint);
                //fakerests[queryResource]?.collections.endpoints.updateOne(result.id, result);
                return null;
            }


        });
        if (promises) {

            return Promise.all(promises);

        }
        else {
            return Promise.resolve();
        }

    }


}
