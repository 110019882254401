import React from 'react'
import PropTypes from 'prop-types'
import { BooleanInput, required } from 'react-admin'

const CheckboxWidget = props => (
    <div>
        <BooleanInput
            source={props.fieldName}
            label={props.label}
            {...props.styleProps}
        />
    </div>
)

CheckboxWidget.propTypes = {
    fieldName: PropTypes.string,
    label: PropTypes.string,
    normalizer: PropTypes.func,
}

export default CheckboxWidget
