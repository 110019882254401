import React from 'react'
import PropTypes from 'prop-types'
import { DisabledInput } from 'react-admin'

const ReadonlyWidget = props => (
    <div>
        <DisabledInput
            source={props.fieldName}
            label={props.label}
            {...props.styleProps}
        />
    </div>
)

ReadonlyWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    fieldName: PropTypes.string,
    label: PropTypes.string,
    normalizer: PropTypes.func,
}

export default ReadonlyWidget
