import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { Datagrid } from 'react-admin'

const styles = {
    table: {
        width: '100%'
    },
};

const FullWidthDataGrid = withStyles(styles)(({
    classes,
    ...props
}) => (
    <Datagrid classes={{ table: classes.table }} {...props} />
));

export default FullWidthDataGrid