import React from 'react'
import PropTypes from 'prop-types'
import { SelectInput, ReferenceInput, required } from 'react-admin';

const AlarmSelect = props => {
    const options = props.enumValues || props.schema.enum;
    const optionNames = props.enumTitles || props.schema.enum_titles || props.schema.enum_titles || options;
    const companyFilter = props.context.companyId ? { companyId: props.context.companyId  } : {}
    return (
        <ReferenceInput
            filter={ { ...companyFilter, visible: true, active: true }}
            validate={ props.required ?  required() : null} emptyValue={null} allowEmpty={true} label="Alarm" resource={props.context.resource} source={props.fieldName} reference="alarms">
            <SelectInput fullWidth label="Alarm" optionText="name" />
        </ReferenceInput>
    )
}

AlarmSelect.propTypes = {
    schema: PropTypes.object.isRequired,
    fieldName: PropTypes.string,
    label: PropTypes.string,
}

export default AlarmSelect
