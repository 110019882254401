import React from 'react';
import { withDataProvider, GET_LIST } from 'react-admin';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup';
import '../styles/prism.css'
import TextFileViewer from '../common/text-file-viewer'

class InstanceMetadataViewerView extends React.Component {
    componentDidMount = () => {
        const { dataProvider } = this.props;

        if (this.props.instanceId) {
            dataProvider(GET_LIST, 'integrationinstancemetadatas/getfullinstancemetadata', {
                pagination: { page: 1, perPage: 1 },
                sort: { field: 'id', order: 'ASC' },
                filter: { integrationInstanceId: this.props.instanceId }
            })
            .then((result) => this.setState({ data: result.data[0] }))
            .catch((e) => { })
        }
    }

    render() {
        if (this.state && this.state.data && this.state.data.metadata) {
            return (
                <TextFileViewer record={this.state.data} source="metadata" contentType="application/json" />
            )
        } else {
            // show {} if no metadata fetched
            return (
                <TextFileViewer record={{ metadata: {} }} source="metadata" contentType="application/json" />
            )
        }
    }
}

export const InstanceMetadataViewer = withDataProvider(InstanceMetadataViewerView);
