import React from 'react';
import { withDataProvider, CREATE } from 'react-admin';
import Button from '@material-ui/core/Button';

class StartRunButtonView extends React.Component {
    handleClick = () => {
        const { dataProvider, record } = this.props;
        dataProvider(CREATE, 'scheduling', { data: { integrationInstanceId: record.integrationInstanceId, integrationModuleId: record.integrationModuleId, integrationModuleConfigurationId: record.integrationModuleConfigurationId } }, {
            onSuccess: {
                notification: { body: 'Run started', level: 'info' },
                refresh: true
            },
            onFailure: {
                notification: { body: 'Error: could not start run', level: 'warning' }
            }
        })
    }

    render() {
        const { record } = this.props;
        return record && record.latestRunIsPastEntrypoint ? <Button variant="flat" onClick={this.handleClick}>Start run</Button>
            : null;
    }
}

export const StartRunButton = withDataProvider(StartRunButtonView);