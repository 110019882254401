import React from 'react'
import { withStyles } from '@material-ui/core/styles';
import { ReferenceManyField } from 'react-admin'

const styles = {
};

// hacky...
const FullWidthReferenceManyField = withStyles(styles)(({
    classes,
    ...props
}) => (
    <ReferenceManyField {...props} />
));

export default FullWidthReferenceManyField