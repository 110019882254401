import React from 'react'
import { translate } from 'react-admin'

const PeriodicityField = translate(({ translate, typeSource, numberSource, record = {} }) => {
    const periodicityNumber = record && record[numberSource]
    const periodicityTypeId = record && record[typeSource];
    const translated = translate(`enums.periodicityTypes.${periodicityTypeId}`)
    return (
        <span title={translated}>
            {periodicityNumber} {translated}
        </span>
    )
});

export default PeriodicityField;