import React, { Fragment } from 'react';
import {
    NullableBooleanInput, BooleanInput, AutocompleteInput, Filter, SimpleForm, Create, Edit, SelectInput, ReferenceInput,
    NumberInput, TextInput, List, Datagrid, TextField, ReferenceField, NumberField
} from 'react-admin';
import { REDUX_FORM_NAME, required, FormDataConsumer } from 'ra-core';
import { change } from 'redux-form'

const AlarmFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Company" sort={{ field: 'companyName', order: 'ASC' }} source="companyId" reference="companies" alwaysOn allowEmpty>
            <AutocompleteInput translateChoice={false} optionText="companyName" />
        </ReferenceInput>
        <NullableBooleanInput source="visible" alwaysOn />
        <NullableBooleanInput source="active" alwaysOn />
    </Filter>
);

export const AlarmList = props => (
    <List {...props} filters={<AlarmFilter />} filterDefaultValues={{ visible: true, active: true }} title="Recipient lists" exporter={false} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ReferenceField linkType={false} source="alarmRecipientListId" reference="alarmrecipientlists"><TextField source="name" /></ReferenceField>
            <ReferenceField linkType={false} source="companyId" reference="companies"><TextField source="companyName" /></ReferenceField>
            <NumberField source="throttleMinutes" />
        </Datagrid>
    </List>
);

const AlarmForm = (props) => (
    <SimpleForm {...props}>
        <ReferenceInput source="alarmTemplateId" validate={required()} reference="alarmtemplates"><SelectInput optionText="name" /></ReferenceInput>
        <FormDataConsumer>
            {({ formData, dispatch, ...rest }) =>
                <Fragment>
                    <ReferenceInput sort={{ field: 'companyName', order: 'ASC' }} onChange={value => dispatch(
                            change(REDUX_FORM_NAME, 'alarmRecipientListId', null)
                        )} {...rest} allowEmpty={true} source="companyId" reference="companies" validate={required()}>
                            <AutocompleteInput optionText="companyName" />
                    </ReferenceInput>
                    {formData.companyId && <ReferenceInput {...rest} key={`recipienlists-${formData.companyId}`} filter={{ companyId: formData.companyId }} source="alarmRecipientListId" sort={{ field: 'name', order: 'ASC' }} validate={required()} reference="alarmrecipientlists"><SelectInput optionText="name" /></ReferenceInput>}
                </Fragment>
            }
        </FormDataConsumer>
        <TextInput source="name" validate={required()} />
        <NumberInput source="throttleMinutes" validate={required()} />
        <BooleanInput source="visible" />
        <BooleanInput source="active"/>
    </SimpleForm>
);

export const AlarmEdit = props => (
    <Edit title="Edit alarm" {...props}>
        <AlarmForm />
    </Edit>
);

export const AlarmCreate = props => (
    <Create title="Create alarm" {...props}>
        <AlarmForm />
    </Create>
);