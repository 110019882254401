import React from 'react'
import PropTypes from 'prop-types'
import { map, isEmpty } from 'lodash'
import { SelectInput, required } from 'react-admin';

const ChoiceWidget = props => {
    const options = props.schema.choices || []
    return (
        <div>
            <SelectInput
                label={props.label}
                fullWidth
                source={props.fieldName}
                translateChoice={false}
                validate={props.required ? required() : null}
                choices={map(options, (value, key) => { return { id: value.Value, name: value.Label } })}
                {...props.styleProps}
            />
        </div>
    )
}

{/* {map(options, (value, key) => (
                <MenuItem key={value} value={value} primaryText={optionNames[key]} />
            ))}
        </Field> */}

ChoiceWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    fieldName: PropTypes.string,
    label: PropTypes.string,
}

export default ChoiceWidget
