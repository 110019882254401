import React from 'react';
import { highlight, languages } from 'prismjs/components/prism-core';
import beautify from 'beautify'
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-markup';
import '../styles/prism.css'
import { isObject } from 'lodash'

const wrappedBeautify = (data, { format }) => {
    if (format == "csv") {
        // return data
        return (data || '')
    }

    return beautify(data, { format })
}

languages.csv = {
	'property': { pattern: /[^\r\n",;]+|"(?:[^"]|"")+"(?!")/, greedy: true},
	'separator': /;/
};

const getLanguage = contentType => {
    switch (contentType) {
        case 'application/json':
        case 'text/json':
            return languages.json;
        case 'application/xml':
        case 'text/xml':
            return languages.markup;
        case 'text/csv':
            return languages.csv;
    }

    return languages.json;
}

const getFormat = contentType => {
    switch (contentType) {
        case 'application/json':
        case 'text/json':
            return 'json';
        case 'application/xml':
        case 'text/xml':
            return 'xml';
        case 'text/csv':
            return 'csv';
    }

    return 'json'
}

const TextFileViewer = ({ contentTypeSource, contentType, record, source }) => (
    <pre className={`language-${getFormat(record[contentTypeSource] || contentType)}`}>
        <code className={`language-${getFormat(record[contentTypeSource])}`} dangerouslySetInnerHTML={ { __html: highlight(wrappedBeautify(isObject(record[source]) ? JSON.stringify(record[source]) : record[source] || '', { format: getFormat(record[contentTypeSource] || contentType) }), getLanguage(record[contentTypeSource] || contentType))}}>
        </code>
    </pre>
)

export default TextFileViewer