import React from 'react';
import { withDataProvider, UPDATE } from 'react-admin';
import Button from '@material-ui/core/Button';

class SetEntityStatusToSucceededButtonView extends React.Component {
    handleClick = () => {
        const { dataProvider, record } = this.props;
        dataProvider(UPDATE, 'entities', { id: record.id, trailingPath: `setentitystatustosucceeded/${record.id}` }, {
            onSuccess: {
                notification: { body: 'The entity status was successfully set to succeeded', level: 'info' },
                refresh: true
            },
            onFailure: {
                notification: { body: 'Error: failed to set the entity status to succeeded', level: 'warning' }
            }
        })
    }

    render() {
        const { record } = this.props;
        return record && (record.statusTypeId === 100) ? <Button variant="flat" onClick={this.handleClick}>Set status to succeeded</Button> : null;
    }
}

export const SetEntityStatusToSucceededButton = withDataProvider(SetEntityStatusToSucceededButtonView)