import React from 'react';
import { withDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';

class EntityDataLinkButtonView extends React.Component {
    handleClick = () => {
        const { record } = this.props;
        const token = localStorage.getItem('token');
        window.location.href = `${window.baseApiUrl}/entitydatas/download/${record.id}?token=${token}`
    }

    render() {
        return <Button variant="flat" onClick={this.handleClick}>Download data</Button>;
    }
}

export const EntityDataLinkButton = withDataProvider(EntityDataLinkButtonView)