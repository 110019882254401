export const dataTypes = {
    0: 'Undefined',
    1: 'EcsInvoiceXml',
    2: 'EcsInvoicePdf',
    3: 'EcsInvoiceAttachment',
    4: 'EcsInvoiceJson',
    502: 'PriSupplierInvoiceXml',
    503: 'PriSupplierOrderResponseXml',
    504: 'PriSupplierDispatchAdviceXml',
    600: 'EcsCustomerJson',
    601: 'EcsCustomersJson',
    700: 'EcsProjectJson',
    701: 'EcsProjectsJson',
    800: 'EcsSettingsJson',
    900: 'EcsSupplierJson',
    901: 'EcsSupplierInvoiceJson',
    910: 'EcsExportSupplierInvoiceAttachment',
    920: 'EcsExportSuppliersJson',
    1000: 'EcsInvoicePaymentJson',
    1001: 'EcsSupplierInvoicePaymentJson',
    1100: 'EcsAttendanceTransactionsJson',
    1101: 'EcsAbsenceTransactionsJson',
    1102: 'EcsImportTimeIntervalJson',
    1200: 'EcsTaxReductionsJson',
    1300: 'EcsAccountsJson',
    1301: 'EcsAccountJson',
    1400: 'EcsCostCentersJson',
    1401: 'EcsCostCenterJson',
    1500: 'EcsItemsJson',
    1501: 'EcsExportItemJson',
    1600: 'EcsTermsOfPaymentsJson',
    1601: 'EcsTermsOfPaymentJson',
    1800: 'EcsPaymentMethodJson',
    1801: 'EcsPaymentMethodsJson',
    1900: 'EcsEmployeeJson',
    1901: 'EcsEmployeesJson',

    2000: 'EcsCompanyJson',
    2100: 'EcsInvoiceBalanceJson',
    2110: 'ImportSupplierInvoiceBalanceJson',
    2200: 'EcsExportTimeCodeJson',
    2201: 'EcsExportTimeCodesJson',
    2300: 'EcsExportContactsJson',
    2400: 'EcsExportOrderJson',
    2401: 'EcsExportOrdersJson',
    2410: 'EcsExportOrderRowJson',
    2411: 'EcsExportOrderRowsJson',
    2412: 'EcsImportOrderJson',
    2413: 'EcsImportOrdersJson',
    2414: 'EcsImportOrderRowJson',
    2415: 'EcsImportOrderRowsJson',
    2500: 'EcsExportObjectsJson',
    2600: 'EcsExportGeneralLedgerJson',
    2700: 'EcsExportProjectPhaseJson',
    10000: 'Csv',
    11000: 'Zip',
    20000: 'ExternalData',
    30000: 'Svefaktura',
    31000: 'Finvoice13',
    31001: 'Finvoice30',
    32000: 'Iizy',
    33000: 'EHF21',
    34000: 'PeppolInvoice',
    35000: 'SIE',
    42000: 'EcsImportSupplierImportLogJson',
    45000: 'Ahlsell'
};