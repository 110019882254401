export const statusTypes = {
    0: 'Waiting',
    1: 'In progress',
    2: 'Running',
    100: 'Failed',
    110: 'Timed out',
    120: 'Threw',
    121: 'Threw fatal',
    130: 'Cancelled',
    140: 'Request limit exceeded',
    150: 'Broken circuit',
    160: 'Null reference',
    170: 'Not implemented',
    199: 'Terminated',
    200: 'Succeeded',
    201: 'Succeeded with errors',
    202: 'Succeeded by user',
    300: 'Not done',
    301: 'Partial success'
};