export const entityTypes = {
    0: 'Undefined',
    1: 'Invoice',
    2: 'Invoice payment',
    3: 'Invoice balance',
    10: 'Supplier',
    11: 'Supplier invoice',
    12: 'Supplier invoice payment',
    13: 'Supplier order response',
    14: 'Supplier dispatch advice',
    15: 'Supplier Invoice EDI',
	16: 'Supplier Invoice Edi',
    16: 'Supplier Discount',
	17: 'Supplier Invoice Picture',
	18: 'Supplier Categories',
    20: 'Time, grouped by date and code',
    21: 'Time, continuous',
    22: 'Time, grouped by date and code and project',
    23: 'Time, continuous, with price',
    29: 'Time, single',
    30: 'Account',
    40: 'Cost center',
    50: 'Item',
	51: 'Item Price',
    60: 'Terms of payment',
    70: 'Customer',
	75: 'Customer Categories',
    80: 'Project',
    90: 'Payment method',
    100: 'Employee',
    110: 'Company',
    129: 'Time, single, with misc',
    140: 'Time Code',
    150: 'Contact',
    160: 'Order',
    161: 'Order Row',
    170: 'Object',
    180: 'SIE',
	181: 'CSV',
    190: 'External Status',
    200: 'General Ledger',
    201: 'General Ledger Row',
    210: 'Project Phase',
    220: 'Tracked SFTP',
    230: 'Payslip',
    240: 'ImpLevLogg',
    241: 'ImpLevRad',
    242: 'Journal Response',
    243: 'Attestation Response',
    "Undefined":"0", //For Id Maping 
    "Invoice":"1",
    "Invoice payment":2,
    "Invoice balance":3,
    "Supplier":"10",
    "Supplier invoice":"11",
    "Supplier invoice payment":"12",
    "Supplier order response":"13",
    "Supplier dispatch advice":"14",
    "Supplier Invoice EDI":"15",
    "Supplier Discount":"16",
    "Time, grouped by date and code":"20",
    "Time, continuous":"21",
    "Time, grouped by date and code and project":"22",
    "Time, continuous, with price":"23",
    "Time, single":"29",
    "Account":"30",
    "Cost center":"40",
    "Item":"50",
    "Terms of payment":"60",
    "Customer": "70",
    "CustomerCategories": "75",
    "Project":"80",
    "Payment method":"90",
    "Employee":"100",
    "Company":"110",
    "Time, single, with misc":"129",
    "Time Code":"140",
    "Contact":"150",
    "Order":"160",
    "Order Row":"161",
    "Object":"170",
    "SIE":"180",
    "External Status":"190",
    "General Ledger":"200",
    "General Ledger Row":"201",
    "Project Phase":"210",
    "Tracked SFTP":"220",
    "Payslip":"230",
    "ImpLevLogg":"240",
    "ImpLevRad":"241",
    "Journal Response":"242",
    "Attestation Response": "243",
    "Token": "244",
    "Salary": "245",
};