import React from 'react';
import { withDataProvider, CREATE, showNotification } from 'react-admin';
import Button from '@material-ui/core/Button';
import { push } from 'react-router-redux';
import {VALIDATE} from '../data-provider/data-provider'

class ValidateButtonView extends React.Component {
    handleClick = () => {
        const { dataProvider,dispatch, record, formData} = this.props;
        dataProvider(VALIDATE, 'settingsvalidation/validateintegration', { id: record, data: { formData } })
         .then((data) => {
            console.log(data);
            if(data.data.status === 0){
               dispatch(showNotification('ok'));
            }else{
               dispatch(showNotification(data.data.message, 'warning'));
            }
         })
         .catch((e)=> {
            dispatch(showNotification('error', 'warning'));
         })
    }

    render() {
        const { record, IsValidatedAfterInstanceCreation, IsValidatedBeforeInstanceCreation } = this.props;
        return (record != null && (IsValidatedAfterInstanceCreation || IsValidatedBeforeInstanceCreation)) ? <Button variant="flat" onClick={this.handleClick}>Validate</Button> : null;
    }
}

export const ValidateButton = withDataProvider(ValidateButtonView);
