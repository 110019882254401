export const periodicityTypes = {
    0: 'disabled',
    1: 'seconds',
    2: 'minutes',
    3: 'hours',
    4: 'days',
    5: 'weeks',
    6: 'months',
    7: 'years',
    20: 'every day',
    21: 'Date of month'
};
