import { DefaultTheme } from '../../index'
import ArrayWidget from './ArrayWidget'
// import HiddenWidget from './HiddenWidget'
import StringWidget from './StringWidget'
// import TextareaWidget from './TextareaWidget'
import ChoiceWidget from './ChoiceWidget'
import AlarmSelect from './AlarmSelect'
import NumberWidget from './NumberWidget'
import IntegerWidget from './IntegerWidget'
import ReadonlyWidget from './ReadonlyWidget'
import HiddenWidget from './HiddenWidget'
import CheckboxWidget from './CheckboxWidget'
import PasswordWidget from './PasswordWidget'

export default {
    ...DefaultTheme,
    array: ArrayWidget,
    // textarea: TextareaWidget,
    string: StringWidget,
    number: NumberWidget,
    integer: IntegerWidget,
    choice: ChoiceWidget,
    Select: ChoiceWidget,
    AlarmSelect: AlarmSelect,
    Readonly: ReadonlyWidget,
    hidden: HiddenWidget,
    Check: CheckboxWidget,
    Password: PasswordWidget,
    None: () => null
}
