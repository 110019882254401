import React from "react";
import { TextInput, required } from 'react-admin';

const PasswordWidget = props => {
    return <div>
        <TextInput
            type="password"
            source={props.fieldName}
            label={props.label}
            validate={props.required ? required() : null}
            {...props.styleProps}
        />
    </div>;
};

export default PasswordWidget;
