import React from 'react'
import { Filter, ReferenceInput, AutocompleteInput, NumberInput, TextInput } from 'react-admin'

export const ErrorFilterFilters = ({ showIntegration, showId, showMessage, ...props }) => (
    <Filter {...props}>
        {showId && <NumberInput label="Id" source="id" alwaysOn allowEmpty />}
        {showIntegration && <ReferenceInput sort={{ field: 'name', order: 'ASC' }} perPage={100} label="Integration" source="integrationId" reference="integrations" alwaysOn allowEmpty>
            <AutocompleteInput allowEmpty={true} translateChoice={false} optionText="name" />
        </ReferenceInput>}
        {showMessage && <TextInput label="Message" source="resultMessage" alwaysOn allowEmpty />}
    </Filter>
);