import React from 'react';
import { withDataProvider, UPDATE } from 'react-admin';
import Button from '@material-ui/core/Button';

class ReturnEntityToSourceButtonView extends React.Component {
    handleClick = () => {
        const { dataProvider, record } = this.props;
        dataProvider(UPDATE, 'entities', { id: record.id, trailingPath: `return/${record.id}` }, {
            onSuccess: {
                notification: { body: 'The entity was successfully returned to its source', level: 'info' },
                refresh: true
            },
            onFailure: {
                notification: { body: 'Error: failed to return the entity to its source', level: 'warning' }
            }
        })
    }

    render() {
        const { record } = this.props;
        return record && (record.statusTypeId < 200 || record.statusTypeId === 202) ? <Button variant="flat" onClick={this.handleClick}>Re-run from source</Button> : null;
    }
}

export const ReturnEntityToSourceButton = withDataProvider(ReturnEntityToSourceButtonView)