import React from 'react';
import { withDataProvider, UPDATE } from 'react-admin';
import Button from '@material-ui/core/Button';

class SetStatusToSucceededButtonView extends React.Component {
    handleClick = () => {
        const { dataProvider, record } = this.props;
        dataProvider(UPDATE, 'tasks', { trailingPath: `setstatustosucceeded/${record.id}` }, {
            onSuccess: {
                notification: { body: 'Status of task updated', level: 'info' },
                refresh: true
            },
            onFailure: {
                notification: { body: 'Error: failed to update status of task', level: 'warning' }
            }
        })
    }

    render() {
        const { record } = this.props;
        return record && (record.statusTypeId === 121 || record.statusTypeId === 199) ? <Button variant="flat" onClick={this.handleClick}>Set status to succeeded</Button> : null;
    }
}

export const SetStatusToSucceededButton = withDataProvider(SetStatusToSucceededButtonView)