import React from 'react'
import PropTypes from 'prop-types'
import { TextInput, required } from 'react-admin'

const StringWidget = props => (
    <div>
        <TextInput
            style={{ "width": "100%" }}
            source={props.fieldName}
            label={props.label}
            validate={ props.required ?  required() : null}
            {...props.styleProps}
        />
    </div>
)

StringWidget.propTypes = {
    schema: PropTypes.object.isRequired,
    fieldName: PropTypes.string,
    label: PropTypes.string,
    normalizer: PropTypes.func,
}

export default StringWidget
