import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import { find } from 'lodash'

export default url => (type, params) => {
    
    if (type === AUTH_LOGIN) {
        debugger
        
        localStorage.setItem('token', params);
        const request = new Request(`${url}/api/authentication/usertokeninfo`, {
            method: 'POST',
            headers: new Headers({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${params}`  }),
        })
        return fetch(request)
            .then(response => {
                //debugger
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then((user) => {
                localStorage.setItem('user', JSON.stringify(user));
                //debugger
                setTimeout(() => window.location.href = '/', 100);
            });
    }

    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
    }

    if (type === AUTH_ERROR) {
        const status  = params.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.setItem('redirectTo', window.location.href)
            console.log('error')

            return Promise.reject({ redirectTo: window.location.href });
        }
    }

    if (type === AUTH_CHECK) {
        localStorage.setItem('redirectTo', window.location.href)
        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
    }

    if (type === AUTH_GET_PERMISSIONS) {
        var user = JSON.parse(localStorage.getItem('user'))
        if (!user) {
            localStorage.setItem('redirectTo', window.location.href)
            return Promise.reject()
        }

        var externalCompanyNumberProperty = find(user.properties, p => p.propertyId == 3)
        if (!externalCompanyNumberProperty) {
            return Promise.reject()
        }

        var externalCompanyNumber = parseInt(externalCompanyNumberProperty.propertyValue)
        return Promise.resolve({ externalCompanyNumber, ...user, group: { ...user.group } })
    }

    return Promise.resolve();
}