import React from 'react';
import { withDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';
import beautify from 'beautify'
import { isObject } from 'lodash'

class CopyToClipboardButtonView extends React.Component {

    wrappedBeautify = (data, { format }) => {
        if (format == "csv") {
            // return data
            return (data || '')
        }

        return beautify(data, { format })
    }

    getFormat = contentType => {
        switch (contentType) {
            case 'application/json':
            case 'text/json':
                return 'json';
            case 'application/xml':
            case 'text/xml':
                return 'xml';
            case 'text/csv':
                return 'csv';
        }

        return 'json'
    }

    handleClick = () => {
        const { record, source, contentTypeSource, contentType, formated } = this.props;
        const value = formated ? (this.wrappedBeautify(isObject(record[source]) ? JSON.stringify(record[source]) : record[source] || '', { format: this.getFormat(record[contentTypeSource] || contentType) })) : record[source];
        navigator.clipboard.writeText(value);
    }

    render() {
        return <Button variant="flat" onClick={this.handleClick}>Copy data{this.props.formated ? " formated" : ""}</Button>;
    }
}

export const CopyToClipboardButton = withDataProvider(CopyToClipboardButtonView);

export const CopyToClipboardButtons = props => (
    <div>
        <CopyToClipboardButton {...props} />
        <CopyToClipboardButton {...props} formated={true} />
    </div>
);
