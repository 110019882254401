import React from 'react';
import { withDataProvider, UPDATE } from 'react-admin';
import Button from '@material-ui/core/Button';

class ReRunTaskButtonView extends React.Component {
    handleClick = () => {
        const { dataProvider, record } = this.props;
        dataProvider(UPDATE, 'tasks', { trailingPath: `reruntask/${record.id}` }, {
            onSuccess: {
                notification: { body: 'Queue task', level: 'info' },
                refresh: true
            },
            onFailure: {
                notification: { body: 'Error: failed to queue task', level: 'warning' }
            }
        })
    }

    render() {
        const { record } = this.props;
        return record && (record.statusTypeId === 121 || record.statusTypeId === 199 || record.statusTypeId === 202) ? <Button variant="flat" onClick={this.handleClick}>Rerun Task</Button> : null;
    }
}

export const ReRunTaskButton = withDataProvider(ReRunTaskButtonView)