import React from 'react';
import {
    NullableBooleanInput, BooleanInput, ArrayInput, SimpleFormIterator, AutocompleteInput, Filter, SimpleForm,
    Create, Edit, ReferenceInput, TextInput, List, Datagrid, TextField, ReferenceField, NumberField
} from 'react-admin';

const AlarmRecipientListFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput sort={{ field: 'companyName', order: 'ASC' }} label="Company" source="companyId" reference="companies" alwaysOn allowEmpty>
            <AutocompleteInput translateChoice={false} optionText="companyName" />
        </ReferenceInput>
        <NullableBooleanInput source="visible" alwaysOn />
        <NullableBooleanInput source="active" alwaysOn />
    </Filter>
);

export const AlarmRecipientListList = props => (
    <List {...props} filters={<AlarmRecipientListFilter />} filterDefaultValues={{ visible: true, active: true }} title="Recipient lists" exporter={false} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="name" />
            <ReferenceField source="companyId" linkType={false} reference="companies"><TextField source="companyName" /></ReferenceField>
            <NumberField source="recipients.length" label="# recipients" />
        </Datagrid>
    </List>
);

const AlarmRecipientListForm = (props) => (
    <SimpleForm {...props}>
        <TextInput source="name" />
        <ReferenceInput source="companyId" sort={{ field: 'companyName', order: 'ASC' }} reference="companies"><AutocompleteInput optionText="companyName" /></ReferenceInput>
        <BooleanInput source="visible" />
        <BooleanInput source="active" />
        <ArrayInput source="recipients" label="Recipients">
            <SimpleFormIterator>
                <TextInput label="Recipient" />
            </SimpleFormIterator>
        </ArrayInput>
    </SimpleForm>
);

export const AlarmRecipientListEdit = props => (
    <Edit title="Edit recipient list" {...props}>
        <AlarmRecipientListForm />
    </Edit>
);

export const AlarmRecipientListCreate = props => (
    <Create title="Create recipient list" {...props}>
        <AlarmRecipientListForm />
    </Create>
);